import React from "react";
import { string, number, objectOf, shape, any, object } from "prop-types";
import { Col, Typography } from "antd";
import CtaSecondary from "../Buttons/secondary-cta";
import { viewports, colors } from "../../style-vars";
import Heading from "../../components-v2/Base/HeadingBuilder";

const { Title } = Typography;
const { mdOrSmaller } = viewports;

const ArticleCard = (props) => {
  const { title, publishedDate, snippet, category, sizing, headerImg, linkTo } =
    props;
  const sizingType = sizing === 1 ? 8 : 12;
  return (
    <Col lg={sizingType} sm={24}>
      <div className="articles-card">
        <div className="articles-card__head">
          <img src={headerImg} alt="" loading="lazy" />
          {category.length > 0 ? (
            <span className="articles-card__chip">{category}</span>
          ) : null}
        </div>
        <span className="articles-card__date">{publishedDate}</span>
        <Heading level={3} injectionType={4}>
          {" "}
          {title}{" "}
        </Heading>
        <p>{snippet && snippet}</p>
        <CtaSecondary ctaTitle="Read More" target={linkTo} />
      </div>

      <style jsx>{`
        .articles-card__head {
          position: relative;
          border-radius: 26px;
          margin-bottom: 24px;
        }
        .articles-card__head img {
          width: 100%;
          height: 235px;
          border-radius: 8px;
          object-fit: cover;
        }

        .articles-card__chip {
          color: ${colors.white};
          background-color: ${colors.primary};
          border-bottom-left-radius: 25px;
          border-top-left-radius: 25px;
          padding: 12px;
          position: absolute;
          right: 0;
          bottom: 32px;
          font-weight: 800;
        }

        .articles-card__date {
          font-size: 16px;
          color: ${colors.paragraph};
          opacity: 0.8;
        }

        p {
          color: ${colors.paragraph};
          font-size: 16px;
          opacity: 0.8;
        }

        .articles-card > :global(h4) {
          color: ${colors.black} !important;
          font-size: 18px !important;
          margin-top: 16px;
          margin-bottom: 8px;
          line-height: 1.5 !important;
        }

        .articles-card > :global(.cta-secondary) {
          display: flex;
        }

        @media ${mdOrSmaller} {
          .articles-card {
            margin-bottom: 50px;
          }
        }
      `}</style>
    </Col>
  );
};

ArticleCard.propTypes = {
  title: string,
  publishedDate: string,
  snippet: string,
  category: string,
  sizing: number,
  headerImg: string,
  linkTo: string,
};
ArticleCard.defaultProps = {
  title: "",
  publishedDate: "",
  snippet: "",
  category: "",
  sizing: 1,
  headerImg: "",
  linkTo: "",
};

export default ArticleCard;
